<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useListTable from '@/comp-functions/useListTable';
import printInvoice from '@/components/print-invoice'
import {formatNumber} from '@/utils/formatter'

export default {
  page: {
    title: "Billing List",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  setup () {
    
    const tableColumns = [
      //this.$t('fields.port.name')
      { key: "inv_number", label: 'No. Invoice', thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "order_id", label: "No.Order", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "inv_date", label: "Date", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "vendor_id", label: "Airlines", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "schedule_id", label: "Fligh No", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "route_id", label: "Route", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "colgrandtotal", label: "Grand Total", thClass: 'text-right', tdClass: 'text-right', sortable: true },
      { key: "status", label: "Payment Status", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "action", thClass: 'text-center', tdClass: 'text-center',label: "#" },
    ]
    
    // Table Handlers
    const columnToBeFilter = [
      'inv_number',
      'order_id',
      'location',
      'time_zone'
    ]
    const createInvoice = async (inv_id) => {
      printInvoice(inv_id)
    }
    return {
      formatNumber,
      createInvoice,
      tableColumns,
      columnToBeFilter,
      ...useListTable({
        url: 'master/invoice'
      }),
    }
  },
  data() {
    return {
      title: "Billing List",
      items: []
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="perPageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="searchQuery"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                no-provider-paging
                no-provider-filtering
                :filter-included-fields="columnToBeFilter"
                :fields="tableColumns"
                :items="itemLists"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="searchQuery"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
              >
               <template #cell(order_id)="data">
                  {{ data.item.order.ord_no }}
                </template>
               <template #cell(vendor_id)="data">
                  {{ data.item.invSchedule.vendor.name }}
                </template>
               <template #cell(schedule_id)="data">
                  {{ data.item.invSchedule.sch_flight_no }}
                </template>
               <template #cell(route_id)="data">
                  {{ data.item.invSchedule.originport.prt_initial }} - {{ data.item.invSchedule.destinationport.prt_initial }}
                </template>
               <template #cell(colgrandtotal)="data">
                  {{ formatNumber(data.item.inv_gtotalcharge) }}
                </template>
                 
               <template #cell(status)="data">
                  <div v-if="data.item.payment" class="badge badge-soft-success font-size-14">
                    Paid
                  </div>
                  <div v-if="!data.item.payment" class="badge badge-soft-danger font-size-14">
                    Unpaid
                  </div>
                </template>

                <template v-slot:cell(action)="data">
                  <b-button size="sm" id="invprint" variant="success" @click="createInvoice(data.item.inv_id)">
                    <i class="mdi mdi-file-download-outline align-middle ml-2"></i>
                    Invoice
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalList"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>